<template>
<div>
  <Header/>
  <div class="container-fluid">
    <div class="container py-5 mt-lg-5">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-8">
          <h3 style="color: #FE9800; font-weight: bold; ">{{detail.article_name}}</h3>
          <small style="color:#535353;">{{detail.articletype_name}} | {{detail.day}} {{detail.month}} {{detail.year}}</small>
          <img :src="'https://makutacomm-api.makutapro.id/storage/images/article/'+detail.article_img"  class="img-fluid mb-3 mb-lg-5" alt="" width="100%">
          <span class="mt-2 text-insight" v-html="detail.article_content"></span>
        </div>

        <div class="col-12 col-md-6 col-lg-4 mt-3 mt-lg-0">
          <button class="btn btn-block btn-primary">LATEST NEWS</button>
          <hr>
          <div v-for="insight in insights" :key="insight.index" @click="HandleSubmit()">
            <div class="row">
              <div class="col-4">
                <router-link :to="{ name: 'InsightDetail', params: { article_name: insight.article_name }}" >
                  <img :src="'https://makutacomm-api.makutapro.id/storage/images/article/'+insight.article_img" class="img-fluid" alt="" width="100%">
                </router-link>
              </div>
              <div class="col-8 align-self-center">
                <router-link :to="{ name: 'InsightDetail', params: { article_name: insight.article_name }}" style="text-decoration: none;">
                  <h6 style="color: #FE9800; font-weight: bold; ">{{insight.article_name}}</h6>
                  <small style="color:#535353;">{{insight.articletype_name}} | {{insight.day}} {{insight.month}} {{insight.year}}</small>
                </router-link>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterPage/>
</div>
</template>

<script>
import FooterPage from './FooterPage.vue'
import Header from './Header.vue'
export default {
  components: { Header, FooterPage },
  name: 'InsightDetail',
  data(){
    return{
      detail:[],
      insights:[],
    }
  },
  props:['article_name'],
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
      this.$axios.get('https://makutacomm-adm.makutapro.id/api/detailInsights?article_name=' + this.$route.params.article_name)
      .then((response) => {
      //  console.log(response)
        this.detail = response.data.data[0]
       })
      // this.$axios.get('https://makutacomm-adm.makutapro.id/api/insightsLatestNews')
      // .then((response) => {
      //  console.log(response)
      //   this.insights = response.data
      //  })
        this.$axios.get('https://makutacomm-adm.makutapro.id/api/latestInsight?article_name=' + this.$route.params.article_name)
      .then((response) => {
      //  console.log(response)
        this.insights = response.data.data
       })
    },
    methods:{
      HandleSubmit(){
        window.scrollTo(0, 0),
         this.$axios.get('https://makutacomm-adm.makutapro.id/api/detailInsights?article_name=' + this.$route.params.article_name)
        .then((response) => {
        // console.log(response)
          this.detail = response.data.data[0]
        })
      }

    }
}
</script>

<style scoped>
.text-insight{
  color: black;
  opacity: 0.4;
}
</style>